import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  teamMember: I.TeamMember
}

const ResourceTeamMember = ({ teamMember }: Props) => {
  return (
    <Wrapper>
      {teamMember.name}
      <small><b>{teamMember.role_name}</b></small>
      <small>{teamMember.location_date_time} - {teamMember.timezone}</small>
    </Wrapper>
  );
}

export default ResourceTeamMember;