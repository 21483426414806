/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { LanguagesHTTP } from '../../services/api/languages'
import { PhasesHTTP } from '../../services/api/phase'
import { ProjectsHTTP } from '../../services/api/projects'
import { RolesHTTP } from '../../services/api/roles'
import { TeamMembersHTTP } from '../../services/api/team_members'
import { useSnackbar } from '../SnackbarContext'

const AppContext = createContext<I.AppContext>({
  projects: [],
  phases: [],
  languages: [],
  roles: [],
  teamMembers: [],
  createTeamMember: () => new Promise(() => {}),
  editTeamMember: () => new Promise(() => {}),
  deleteTeamMember: () => new Promise(() => {}),
  createLanguage: () => new Promise(() => {}),
  editLanguage: () => new Promise(() => {}),
  deleteLanguage: () => new Promise(() => {}),
  createProject: () => new Promise(() => {}),
  deleteProject: () => new Promise(() => {}),
})

export const AppProvider: React.FC = ({ children }) => {
  const { renderToastr } = useSnackbar()
  const [projects, setProjects] = useState<I.Project[]>([])
  const [phases, setPhases] = useState<I.Phase[]>([])
  const [languages, setLanguages] = useState<I.Language[]>([])
  const [roles, setRoles] = useState<I.Role[]>([])
  const [teamMembers, setTeamMembers] = useState<I.TeamMember[]>([])

  useEffect(() => {
    handleFetchPhases()
    handleFetchRoles()
    handleFetchLanguages()
    handleFetchTeamMembers()
    handleFetchProjects()
  }, [])

  const handleFetchProjects = useCallback(
    async () =>
      ProjectsHTTP.get()
        .then(({ data }) => setProjects(data))
        .catch((err) => console.log('Error on GET projects: ', err)),
    []
  )

  const handleFetchTeamMembers = useCallback(
    async () =>
      TeamMembersHTTP.get()
        .then(({ data }) => setTeamMembers(data))
        .catch((err) => console.log('Error on GET team members: ', err)),
    []
  )

  const handleFetchRoles = useCallback(
    async () =>
      RolesHTTP.get()
        .then(({ data }) => setRoles(data))
        .catch((err) => console.log('Error on GET roles: ', err)),
    []
  )

  const handleFetchPhases = useCallback(
    async () =>
      PhasesHTTP.get()
        .then(({ data }) => setPhases(data))
        .catch((err) => console.log('Error on GET phases: ', err)),
    []
  )

  const handleFetchLanguages = useCallback(
    async () =>
      LanguagesHTTP.get()
        .then(({ data }) => setLanguages(data))
        .catch((err) => console.log('Error on GET languages: ', err)),
    []
  )
  
  const handleCreateTeamMember = useCallback((data: I.TeamMember) => {
    return TeamMembersHTTP.create(data)
      .then(async ({ data }) => {
        handleFetchTeamMembers()

        return data
      })
      .catch((err) => {
        console.log('Error creating team member: ', err)
        throw new Error(err)
      })
  }, [])

  const handleEditTeamMember = useCallback((data: I.TeamMember) => {
    return TeamMembersHTTP.edit(data)
      .then(async ({ data }) => {
        handleFetchTeamMembers()

        return data
      })
      .catch((err) => {
        console.log('Error editing team member: ', err)
        throw new Error(err)
      })
  }, [])

  const handleDeleteTeamMember = useCallback((id: string) => {
    return TeamMembersHTTP.delete(id)
      .then(async () => {
        handleFetchTeamMembers()
      })
      .catch((err) => {
        console.log('Error deleting team member: ', err)
        throw new Error(err)
      })
  }, [])

  const handleCreateLanguage = useCallback((data: I.Language) => {
    return LanguagesHTTP.create(data)
      .then(async ({ data }) => {
        handleFetchLanguages()

        return data
      })
      .catch((err) => {
        console.log('Error creating language: ', err)
        throw new Error(err)
      })
  }, [])

  const handleEditLanguage = useCallback((data: I.Language) => {
    return LanguagesHTTP.edit(data)
      .then(async ({ data }) => {
        handleFetchLanguages()

        return data
      })
      .catch((err) => {
        console.log('Error editing language: ', err)
        throw new Error(err)
      })
  }, [])

  const handleDeleteLanguage = useCallback((id: string) => {
    return LanguagesHTTP.delete(id)
      .then(async () => {
        handleFetchLanguages()
      })
      .catch((err) => {
        console.log('Error deleting language: ', err)
        throw new Error(err)
      })
  }, [])

  const handleCreateProject = useCallback((data: I.ProjectForm) => {
    return ProjectsHTTP.create(data)
      .then(async ({ data }) => {
        renderToastr('Project created successfully')

        handleFetchProjects()

        return data
      })
      .catch((err) => {
        renderToastr('Error creating project')
        console.log('Error creating project: ', err)
        throw new Error(err)
      })
  }, [])

  const handleDeleteProject = useCallback((id: string) => {
    return ProjectsHTTP.delete(id)
      .then(async () => {
        renderToastr('Project deleted successfully')
        handleFetchProjects()
      })
      .catch((err) => {
        renderToastr('Error on deleting project')
        console.log('Error deleting project: ', err)
        throw new Error(err)
      })
  }, [])

  return (
    <AppContext.Provider
      value={{
        projects,
        phases,
        languages,
        roles,
        teamMembers,
        createTeamMember: handleCreateTeamMember,
        editTeamMember: handleEditTeamMember,
        deleteTeamMember: handleDeleteTeamMember,
        createLanguage: handleCreateLanguage,
        editLanguage: handleEditLanguage,
        deleteLanguage: handleDeleteLanguage,
        createProject: handleCreateProject,
        deleteProject: handleDeleteProject
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useApp = (): I.AppContext => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useApp must be used within a <AppProvider />')
  }

  return context
}

export default AppContext;
