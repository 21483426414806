import { createContext, useContext, useState } from 'react'

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const SnackbarContext = createContext<I.SnackbarContext>({
  renderToastr: () => null
})

const SnackbarProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(false)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function renderToastr(messageValue: string) {
    setOpen(true)
    setMessage(messageValue)
  }

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <SnackbarContext.Provider value={{ renderToastr }}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
      {children}
    </SnackbarContext.Provider>
  )
}

export function useSnackbar(): I.SnackbarContext {
  const context = useContext(SnackbarContext)

  return context
}

export default SnackbarProvider
