/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import Modal from '../../../../components/Modal';
import Chip from '@mui/material/Chip';

interface Props {
  open: boolean;
  onClose: (episodes: I.ProjectEpisode[]) => void;
  episodes: I.ProjectEpisode[];
}

const EpisodeDateModal = ({ onClose, open, episodes }: Props) => {
  const [values, setValues] = useState<I.ProjectEpisode[]>([]);
 
  useEffect(() => {
    setValues(episodes);
  }, [open]);

  const handleClose = (type: 'submit' | 'cancel') => {
    if (type === 'cancel') return onClose(episodes)

    onClose(values)
  }

  const handleChange = (field: 'start_date' | 'due_date', value: Date, position: number) => {
    const newValues = [...values];

    newValues[position][field] = value;

    return setValues(newValues);
  }

  return (
    <Modal
      title={episodes.length + ` Episodes`}
      open={open}
      onClose={() => handleClose('cancel')}
      maxWidth="sm"
    >
      <DialogContent style={{ paddingTop: '0.5rem' }}>
        {values.map(({ episode, start_date, due_date }, i) => (
          <Grid key={i} container spacing={2} sx={{ marginBottom: '1rem' }}>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Chip label={episode} />
            </Grid>
            <Grid item xs={5}>
              <DesktopDatePicker
                onChange={(value) => handleChange('start_date', value!, i)}
                value={start_date}
                label="Start date"
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={5}>
              <DesktopDatePicker
                value={due_date}
                onChange={(value) => handleChange('due_date', value!, i)}
                label="Due date"
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={() => handleClose('cancel')}>Cancel</Button>
        <Button type="button" onClick={() => handleClose('submit')}>Save</Button>
      </DialogActions>
    </Modal>
  );
}

export default EpisodeDateModal;