/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';

import Modal from '../../../../components/Modal';
import { useApp } from '../../../../contexts/AppContext';
import EpisodeDateModal from '../EpisodeDateModal';
import MultipliersModal from '../MultipliersModal';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

interface Props {
  project?: I.Project
  open: boolean;
  onClose: () => void;
}

const colors = [
  { value: 'red', label: 'Red' },
  { value: 'pink', label: 'Pink' },
  { value: 'purple', label: 'Purple' },
  { value: 'violet', label: 'Violet' },
  { value: 'indigo', label: 'Indigo' },
  { value: 'blue', label:  'Blue' },
  { value: 'cyan', label: 'Cyan' },
  { value: 'teal', label: 'Teal' },
  { value: 'green', label: 'Green' },
  { value: 'lime', label: 'Lime' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'orange', label: 'Orange' },
  { value: 'deep-orange', label: 'Deep Orange' },
  { value: 'gray', label: 'Gray' },
]

const defaultValues: I.ProjectForm = {
  name: '',
  content_length: 15,
  start_date: new Date(),
  due_date: new Date(),
  episodes: [],
  languages: [],
  team_members: [],
  mix_techs: [],
  mixers: [],
  qc_mixers: [],
  media_techs: [],
  multipliers: []
}

const ProjectModal = ({ onClose, project, open }: Props) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm<I.ProjectForm>({ defaultValues });
  const { languages, teamMembers, phases, roles, createProject, deleteProject } = useApp();

  const [loading, setLoading] = useState(false);
  const [episodeDatesModal, setEpisodeDatesModal] = useState(false);
  const [multipliersModal, setMultipliersModal] = useState(false);

  const selectedLanguages = watch('languages');
  const episode_count = watch('episode_count');
  const episodes = watch('episodes');
  const level_of_touch = watch('level_of_touch');
  const multipliers = watch('multipliers');
  
  useEffect(() => {
    if (episode_count && episodes.length === 0) {
      const newEpisodes: I.ProjectEpisode[] = []

      for (let x = 0; x < episode_count; x++) {
        newEpisodes.push({ episode: x + 1, start_date: new Date(), due_date: new Date() })
      }

      setValue('episodes', newEpisodes)
    } else if (episode_count && episodes && (episode_count !== episodes.length)) {
      const newEpisodes: I.ProjectEpisode[] = []

      for (let x = 0; x < episode_count; x++) {
        if (episodes[x]) {
          newEpisodes.push(episodes[x])
        } else {
          newEpisodes.push({ episode: x + 1, start_date: new Date(), due_date: new Date() })
        }
      }

      setValue('episodes', newEpisodes)
    }
  }, [episode_count])

  useEffect(() => {
    if (level_of_touch) {
      const newMultipliers: I.ProjectMultiplier[] = phases
        .map((phase, key) => ({ phase_id: phase.id!, multiplier: phase[level_of_touch], name: phase.name, order: key + 1 }))

      setValue('multipliers', newMultipliers)
    }
  }, [level_of_touch])

  useEffect(() => {
    if (project) {
      const { name, color, content_length, start_date, due_date, level_of_touch, languages, episodes, team_members, multipliers } = project

      const mixTechId = roles.find((r) => r.name === 'Mix Tech')?.id;
      const mixerId = roles.find((r) => r.name === 'Mixer')?.id;
      const qcMixerId = roles.find((r) => r.name === 'QC Mixer')?.id;
      const mediaTechId = roles.find((r) => r.name === 'Media Tech')?.id;

      reset({
        name,
        content_length,
        start_date,
        episode_count: episodes.length,
        episodes,
        languages: languages.map((l) => l.language_id),
        due_date,
        level_of_touch,
        color,
        team_members: team_members.map((tm) => tm.id),
        mix_techs: team_members.filter((t) => t.role_id === mixTechId).map((m) => m.id),
        mixers: team_members.filter((t) => t.role_id === mixerId).map((m) => m.id),
        qc_mixers: team_members.filter((t) => t.role_id === qcMixerId).map((m) => m.id),
        media_techs: team_members.filter((t) => t.role_id === mediaTechId).map((m) => m.id),
        multipliers
      })
    }
  }, [project])

  const onSubmit = async (data: I.ProjectForm) => {
    const formData: I.ProjectForm = {
      ...data,
      team_members: [
        ...data.mix_techs,
        ...data.media_techs,
        ...data.mixers,
        ...data.qc_mixers,
      ]
    }

    await createProject(formData)

    handleClose();
  }

  const handleDelete = async () => {
    await deleteProject(project?.id!)

    handleClose();
  }

  const handleClose = () => {
    onClose();
    setLoading(false);
    reset(defaultValues);
  }

  const handleCloseEpisodesModal = (data: I.ProjectEpisode[]) => {
    setValue('episodes', data);
    setEpisodeDatesModal(false);
  }

  const handleCloseMultipliersModal = (data?: I.ProjectMultiplier[]) => {
    const newMultipliers = data || phases.map((phase, key) => ({ phase_id: phase.id!, multiplier: phase[level_of_touch!], name: phase.name, order: key + 1 }))

    setValue('multipliers', newMultipliers);
    setMultipliersModal(false);
  }

  return (
    <>
      <Modal
        title={project ? 'Edit Project' : 'Create Project'}
        open={open}
        onClose={handleClose}
        maxWidth="xs"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ paddingTop: '0.5rem' }}>
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={Boolean(project)}
                  label="Name"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={Boolean(error) && 'Required'}
                  style={{ marginBottom: '1rem' }}
                  required
                  fullWidth 
                />
              )}
            />
            <Controller
              name="content_length"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <Typography gutterBottom>Content Length *</Typography>
                  <Slider
                  {...field}
                  disabled={Boolean(project)}
                  defaultValue={15}
                  getAriaValueText={(value) => `${value} minutes`}
                  valueLabelDisplay="auto"
                  step={15}
                  marks
                  min={15}
                  max={120}
                />
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="episode_count"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  fullWidth
                  disabled={Boolean(project)}
                  label="Episode Count"
                  variant="outlined"
                  decimalScale={0}
                  decimalSeparator="."
                  fixedDecimalScale
                  isNumericString
                  value={field.value}
                  onValueChange={({ floatValue }) => field.onChange(floatValue)}
                  customInput={TextField}
                  required
                  error={Boolean(error)}
                  helperText={Boolean(error) && 'Required'}
                  style={{ marginBottom: '1rem' }}
                  allowNegative={false}
                  InputProps={{
                    endAdornment: (
                      <IconButton disabled={!episode_count || episode_count === 0} onClick={() => setEpisodeDatesModal(true)}>
                        <DateRangeIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="languages"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="languages-label">Languages {selectedLanguages?.length > 0 && `(${selectedLanguages.length})`} *</InputLabel>
                  <Select
                    {...field}
                    disabled={Boolean(project)}
                    multiple
                    labelId="languages-label"
                    label={`Languages ${selectedLanguages?.length > 0 && `(${selectedLanguages.length})`} *`}
                    onChange={(e) => onChange(e.target.value)}
                    error={Boolean(error)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {languages.map((lg) => (<MenuItem value={lg.id}>{lg.name} - {lg.code}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="start_date"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DesktopDatePicker
                  {...field}
                  disabled={Boolean(project)}
                  label="Start date"
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ marginBottom: '1rem' }}
                      fullWidth
                      error={Boolean(error)}
                      helperText={Boolean(error) && <FormHelperText>Required</FormHelperText>}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="due_date"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DesktopDatePicker
                  {...field}
                  disabled={Boolean(project)}
                  label="Due date"
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ marginBottom: '1rem' }}
                      fullWidth
                      error={Boolean(error)}
                      helperText={Boolean(error) && <FormHelperText>Required</FormHelperText>}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="mix_techs"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="mixtechs-label">Mix Techs</InputLabel>
                  <Select
                    {...field}
                    disabled={Boolean(project)}
                    multiple
                    labelId="mixtechs-label"
                    label="Mix Techs *"
                    onChange={(e) => onChange(e.target.value)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {teamMembers.filter((tm) => tm.role_name === 'Mix Tech').map((tm) => (<MenuItem value={tm.id}>{tm.name}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="mixers"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="mixers-label">Mixers</InputLabel>
                  <Select
                    {...field}
                    disabled={Boolean(project)}
                    multiple
                    labelId="mixers-label"
                    label="Mixers *"
                    onChange={(e) => onChange(e.target.value)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {teamMembers.filter((tm) => tm.role_name === 'Mixer').map((tm) => (<MenuItem value={tm.id}>{tm.name}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="qc_mixers"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="qcmixers-label">QC Mixers</InputLabel>
                  <Select
                    {...field}
                    multiple
                    disabled={Boolean(project)}
                    labelId="qcmixers-label"
                    label="QC Mixers *"
                    onChange={(e) => onChange(e.target.value)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {teamMembers.filter((tm) => tm.role_name === 'QC Mixer').map((tm) => (<MenuItem value={tm.id}>{tm.name}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="media_techs"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="media_techs-label">Media Techs</InputLabel>
                  <Select
                    {...field}
                    disabled={Boolean(project)}
                    multiple
                    labelId="media_techs-label"
                    label="Media Techs *"
                    onChange={(e) => onChange(e.target.value)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {teamMembers.filter((tm) => tm.role_name === 'Media Tech').map((tm) => (<MenuItem value={tm.id}>{tm.name}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="level_of_touch"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="level_of_touch-label">Level of Touch</InputLabel>
                  <Select
                    {...field}
                    disabled={Boolean(project)}
                    labelId="level_of_touch-label"
                    label="Level of Touch *"
                    endAdornment={(
                      <IconButton disabled={!multipliers || multipliers.length === 0} onClick={() => setMultipliersModal(true)}>
                        <TimesOneMobiledataIcon />
                      </IconButton>
                    )}
                    onChange={(e) => onChange(e.target.value)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    <MenuItem value="normal_touch">Normal touch</MenuItem>
                    <MenuItem value="high_touch">High touch</MenuItem>
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="color"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="color-label">Color *</InputLabel>
                  <Select
                    {...field}
                    disabled={Boolean(project)}
                    labelId="color-label"
                    label="Color *"
                    onChange={(e) => onChange(e.target.value)}
                    error={Boolean(error)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {colors.map((c) => (<MenuItem value={c.value}>{c.label}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleDelete} type="button" color="error" disabled={loading || !project}>Delete</Button>
            <div>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  marginRight: '1rem'
                }}
              />
            )}
            <Button onClick={handleClose} type="button" disabled={loading}>Cancel</Button>
            <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={loading || Boolean(project)}>Save</Button>
            </div>
          </DialogActions>
        </form>
      </Modal>
      <EpisodeDateModal
        open={episodeDatesModal}
        onClose={handleCloseEpisodesModal}
        episodes={episodes}
      />
      <MultipliersModal
        open={multipliersModal}
        onClose={handleCloseMultipliersModal}
        level_of_touch={level_of_touch}
        multipliers={multipliers}
      />
    </>
  );
}

export default ProjectModal;