import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useApp } from '../../contexts/AppContext';

const Phases = () => {
  const { phases } = useApp()

  const getLanguageRule = (phase: I.Phase) => {
    if (phase.language_rule === 'all_simultaneously') {
      return 'All simultaneously'
    }

    if (phase.language_rule === 'except_language') {
      return `All languages except language from ${phases.find((p) => p.id === phase.phase_id_rule)?.name} phase`
    }

    if (phase.language_rule === 'same_language') {
      return `Same language as ${phases.find((p) => p.id === phase.phase_id_rule)?.name} phase`
    }

    if (phase.language_rule === 'only_one') {
      return `Only one`
    }

    return 'All languages'
  }

  return (
    <>
      <div style={{ margin: '1rem', paddingBottom: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '1rem' }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Depends on</TableCell>
                <TableCell>Language rule</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Normal Touch</TableCell>
                <TableCell>High Touch</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {phases.map((ph, index) => (
                <TableRow
                  hover
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{ph.name}</TableCell>
                  <TableCell>{phases.find((p) => ph.dependency_phase_id === p.id)?.name}</TableCell>
                  <TableCell>{getLanguageRule(ph)}</TableCell>
                  <TableCell>{ph.role_name}</TableCell>
                  <TableCell>{ph.normal_touch}x</TableCell>
                  <TableCell>{ph.high_touch}x</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </>
  );
}

export default Phases;