import axios from 'axios'

const apiAdapter = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const { get, put, patch, post, delete: destroy } = apiAdapter

export { get, put, post, patch, destroy }
export default apiAdapter
