import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ProjectModal from '../../pages/Projects/shared/ProjectModal';
import { useState } from 'react';

export default function Header() {
  const [modal, setModal] = useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ProjectModal open={modal} onClose={() => setModal(false)} />
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Scheduler
          </Typography>
          <Link to="/">
            <Button color="inherit">Home</Button>
          </Link>
          <Link to="/projects">
            <Button color="inherit">Projects</Button>
          </Link>
          <Link to="/settings">
            <Button color="inherit">Settings</Button>
          </Link>
          <Button color="info" variant="contained" style={{ margin: '0 1rem' }} onClick={() => setModal(true)}>Create Project</Button>
          <Button color="inherit">LOGOUT</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
