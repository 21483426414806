import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import Button from '@mui/material/Button';

const Wrapper = styled.div`
  border-bottom: 1px solid #d8d9da;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 5px;
`

interface Props {
  scheduler: any
}

const Toolbar = ({ scheduler }: Props) => {
  return (
    <Wrapper>
      <Button onClick={() => scheduler.current.instance.shiftPrevious()} style={{ color: 'rgba(0,0,0,0.54)' }}>Previous day</Button>
      <Button onClick={() => scheduler.current.instance.shiftNext()} style={{ color: 'rgba(0,0,0,0.54)' }}>Next day</Button>
      <IconButton onClick={() => scheduler.current.instance.zoomIn()}><ZoomIn /></IconButton>
      <IconButton onClick={() => scheduler.current.instance.zoomOut()}><ZoomOut /></IconButton>
    </Wrapper>
  );
}

export default Toolbar;