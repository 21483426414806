import { useMemo, useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';

import Modal from '../../../../components/Modal';
import timezones from '../../../../utils/timezones';
import countries from '../../../../utils/countries';
import { useApp } from '../../../../contexts/AppContext';
import { useSnackbar } from '../../../../contexts/SnackbarContext';

interface Props {
  teamMember?: I.TeamMember
  open: boolean;
  onClose: () => void;
}

const defaultValues = {
  name: '',
  role_id: '',
  country: '',
  timezone: '',
  start_date: new Date(),
}

const AddTeamMemberModal = ({ onClose, teamMember, open }: Props) => {
  const { control, handleSubmit, reset } = useForm<I.TeamMember>({ defaultValues });

  const availableTimezones = useMemo(() => timezones, []);
  const availableCountries = useMemo(() => countries, []);

  const { renderToastr } = useSnackbar();
  const { roles, createTeamMember, editTeamMember, deleteTeamMember } = useApp();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (teamMember) {
      reset(teamMember);
    }
  }, [teamMember, reset])

  const onSubmit = async (data: I.TeamMember) => {
    try {
      setLoading(true);

      if (teamMember) {
        await editTeamMember(data);
      } else {
        await createTeamMember(data);
      }

      handleClose();
      renderToastr('Team member created successfully');
    } catch (e) {
      setLoading(false);
      renderToastr('Error editing team member');
      console.log('Error editing team member: ', e);
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true);

      await deleteTeamMember(teamMember?.id!);

      handleClose();
      renderToastr('Team member deleted successfully');
    } catch (e) {
      setLoading(false);
      renderToastr('Error deleting team member');
      console.log('Error deleting team member: ', e);
    }
  }

  const handleClose = () => {
    onClose();
    setLoading(false);
    reset(defaultValues);
  }

  return (
      <Modal
        title={teamMember ? 'Edit Team Member' : 'Add Team Member'}
        open={open}
        onClose={handleClose}
        maxWidth="xs"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ paddingTop: '0.5rem' }}>
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={Boolean(error) && 'Required'}
                  style={{ marginBottom: '1rem' }}
                  required
                  fullWidth 
                />
              )}
            />
            <Controller
              name="role_id"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="role-label">Role *</InputLabel>
                  <Select
                    {...field}
                    labelId="role-label"
                    label="Role *"
                    onChange={(e) => onChange(e.target.value)}
                    error={Boolean(error)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {roles.map((role) => (<MenuItem value={role.id}>{role.name}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="country"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="country-label">Country *</InputLabel>
                  <Select
                    {...field}
                    labelId="country-label"
                    label="Country *"
                    onChange={(e) => onChange(e.target.value)}
                    error={Boolean(error)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {availableCountries.map((c) => (<MenuItem value={c.name}>{c.name}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="timezone"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={Boolean(error)} style={{ marginBottom: '1rem' }}>
                  <InputLabel id="timezone-label">Location/Timezone *</InputLabel>
                  <Select
                    {...field}
                    labelId="timezone-label"
                    label="Location/Timezone *"
                    onChange={(e) => onChange(e.target.value)}
                    error={Boolean(error)}
                  >
                    <MenuItem value={-1} disabled>Select an option</MenuItem>
                    {availableTimezones.map((value) => (<MenuItem value={value}>{value}</MenuItem>))}
                  </Select>
                  {Boolean(error) && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              )}
            />
            <Controller
              name="start_date"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  label="Start date"
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />
          </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleDelete} type="button" color="error" disabled={loading || !teamMember}>Delete</Button>
          <div>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                marginRight: '1rem'
              }}
            />
          )}
          <Button onClick={handleClose} type="button" disabled={loading}>Cancel</Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={loading}>Save</Button>
          </div>
        </DialogActions>
      </form>
    </Modal>
  );
}

export default AddTeamMemberModal;