import { useState } from 'react';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';

import LanguageModal from './shared/LanguageModal';
import { useApp } from '../../contexts/AppContext';

const Languages = () => {
  const { languages } = useApp()

  const [modal, setModal] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState<I.Language>()

  const handleClose = () => {
    setModal(false)
    setSelectedLanguage(undefined)
  }

  return (
    <>
      <div style={{ margin: '1rem', paddingBottom: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Button type="button" variant="contained" onClick={() => setModal(true)}>ADD LANGUAGE</Button>
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '1rem' }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Code</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {languages.map((lg, index) => (
                <TableRow
                  hover
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{lg.name}</TableCell>
                  <TableCell align="right">{lg.code}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        setSelectedLanguage(lg)
                        setModal(true)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <LanguageModal open={modal} onClose={handleClose} language={selectedLanguage} />
    </>
  );
}

export default Languages;