import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const Modal = ({ title, open, onClose, children, maxWidth = 'sm' }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth}>
      <DialogTitle style={{ marginBottom: '0.5rem' }}>{title}</DialogTitle>
      {children}
    </Dialog>
  );
}

export default Modal
