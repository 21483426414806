import { useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';

import Modal from '../../../../components/Modal';
import { useApp } from '../../../../contexts/AppContext';
import { useSnackbar } from '../../../../contexts/SnackbarContext';

interface Props {
  language?: I.Language
  open: boolean;
  onClose: () => void;
}

const defaultValues = {
  name: '',
  code: ''
}

const LanguageModal = ({ onClose, language, open }: Props) => {
  const { control, handleSubmit, reset } = useForm<I.Language>({ defaultValues });

  const { renderToastr } = useSnackbar();
  const { createLanguage, editLanguage, deleteLanguage } = useApp();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (language) {
      reset(language);
    }
  }, [language, reset])

  const onSubmit = async (data: I.Language) => {
    try {
      setLoading(true);

      if (language) {
        await editLanguage(data);
      } else {
        await createLanguage(data);
      }

      handleClose();
      renderToastr('Language created successfully');
    } catch (e) {
      setLoading(false);
      renderToastr('Error editing Language');
      console.log('Error editing Language: ', e);
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true);

      await deleteLanguage(language?.id!);

      handleClose();
      renderToastr('Language deleted successfully');
    } catch (e) {
      setLoading(false);
      renderToastr('Error deleting Language');
      console.log('Error deleting Language: ', e);
    }
  }

  const handleClose = () => {
    onClose();
    setLoading(false);
    reset(defaultValues);
  }

  return (
      <Modal
        title={language ? 'Edit Language' : 'Add Language'}
        open={open}
        onClose={handleClose}
        maxWidth="xs"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ paddingTop: '0.5rem' }}>
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={Boolean(error) && 'Required'}
                  style={{ marginBottom: '1rem' }}
                  required
                  fullWidth 
                />
              )}
            />
            <Controller
              name="code"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Code"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={Boolean(error) && 'Required'}
                  style={{ marginBottom: '1rem' }}
                  required
                  fullWidth 
                />
              )}
            />
          </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleDelete} type="button" color="error" disabled={loading || !language}>Delete</Button>
          <div>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                marginRight: '1rem'
              }}
            />
          )}
          <Button onClick={handleClose} type="button" disabled={loading}>Cancel</Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={loading}>Save</Button>
          </div>
        </DialogActions>
      </form>
    </Modal>
  );
}

export default LanguageModal;