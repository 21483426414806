import { useState, useEffect } from 'react';

import NumberFormat from 'react-number-format';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Modal from '../../../../components/Modal';

interface Props {
  open: boolean;
  onClose: (multipliers?: I.ProjectMultiplier[]) => void;
  level_of_touch?: 'normal_touch' | 'high_touch';
  multipliers: I.ProjectMultiplier[];
}

const MultipliersModal = ({ onClose, open, level_of_touch, multipliers }: Props) => { 
  const [values, setValues] = useState<I.ProjectMultiplier[]>([]);

  useEffect(() => {
    setValues(multipliers);
  }, [open, multipliers]);

  const handleClose = (type: 'submit' | 'cancel') => {
    if (type === 'cancel') return onClose()

    onClose(values)
  }

  const handleChange = (value: number, position: number) => {
    const newValues = [...values];

    newValues[position]['multiplier'] = value;

    return setValues(newValues);
  }

  const title = level_of_touch === 'normal_touch' ? 'Normal Touch Multipliers' : 'High Touch Multipliers';

  const hasEmptyMultiplier = values.some((value) => !value.multiplier);

  return (
    <Modal
      title={title}
      open={open}
      onClose={() => handleClose('cancel')}
      maxWidth="xs"
    >
      <DialogContent style={{ paddingTop: '0.5rem' }}>
        {values.map(({ name, multiplier }, i) => (
          <Grid key={i} container spacing={2} sx={{ marginBottom: '1rem' }}>
            <Grid item xs={12}>
              <NumberFormat
                fullWidth
                label={name}
                variant="outlined"
                value={multiplier}
                decimalScale={2}
                decimalSeparator="."
                isNumericString
                onValueChange={({ floatValue }) => handleChange(floatValue!, i)}
                customInput={TextField}
                required
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={() => handleClose('cancel')}>Cancel</Button>
        <Button type="button" onClick={() => handleClose('submit')} disabled={hasEmptyMultiplier}>Save</Button>
      </DialogActions>
    </Modal>
  );
}

export default MultipliersModal;