import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Settings from "./pages/Settings";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/projects" component={Projects} />
      <Redirect exact from="/settings" to="/settings/team-members" />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/settings/team-members" component={Settings} />
      <Route exact path="/settings/languages" component={Settings} />
      <Route exact path="/settings/phases" component={Settings} />
    </Switch>
  )
}

export default Routes;
