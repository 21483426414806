import { useState } from 'react';

import { format } from 'date-fns';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';

import TeamMemberModal from './shared/TeamMemberModal';
import { useApp } from '../../contexts/AppContext';

const TeamMembers = () => {
  const { teamMembers } = useApp()

  const [modal, setModal] = useState(false)
  const [selectedTeamMember, setSelectedTeamMember] = useState<I.TeamMember>()

  const handleClose = () => {
    setModal(false)
    setSelectedTeamMember(undefined)
  }

  return (
    <>
      <div style={{ margin: '1rem', paddingBottom: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Button type="button" variant="contained" onClick={() => setModal(true)}>ADD TEAM MEMBER</Button>
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '1rem' }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Role</TableCell>
                <TableCell align="right">Local Date Time</TableCell>
                <TableCell align="right">Start date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map((tm, index) => (
                <TableRow
                  hover
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{tm.name}</TableCell>
                  <TableCell align="right">{tm.role_name}</TableCell>
                  <TableCell align="right">{tm.country} - {tm.timezone} - {tm.location_date_time}</TableCell>
                  <TableCell align="right">{format(new Date(tm.start_date), 'MM/dd/yyyy')}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        setSelectedTeamMember(tm)
                        setModal(true)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <TeamMemberModal open={modal} onClose={handleClose} teamMember={selectedTeamMember} />
    </>
  );
}

export default TeamMembers;