import { useState } from 'react';

import { format } from 'date-fns';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';

import ProjectModal from './shared/ProjectModal';
import { useApp } from '../../contexts/AppContext';

const Projects = () => {
  const { projects } = useApp()

  const [modal, setModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState<I.Project>()

  const handleClose = () => {
    setModal(false)
    setSelectedProject(undefined)
  }

  return (
    <>
      <div style={{ margin: '1rem', padding: '1rem 0', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Button type="button" variant="contained" onClick={() => setModal(true)}>CREATE PROJECT</Button>
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '1rem' }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Episode Count</TableCell>
                <TableCell align="right">Languages</TableCell>
                <TableCell align="right">Team Size</TableCell>
                <TableCell align="right">Start date</TableCell>
                <TableCell align="right">Due date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{project.name}</TableCell>
                    <TableCell align="right">{project.episodes.length}</TableCell>
                    <TableCell align="right">{project.languages.length}</TableCell>
                    <TableCell align="right">{project.team_members.length}</TableCell>
                    <TableCell align="right">{format(new Date(project.start_date), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align="right">{format(new Date(project.due_date), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => {
                        setSelectedProject(project)
                        setModal(true)
                      }}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
      <ProjectModal open={modal} onClose={handleClose} project={selectedProject} />
    </>
  );
}

export default Projects;