import { SchedulerConfig } from '@bryntum/schedulerpro';
import { BryntumScheduler } from '@bryntum/schedulerpro-react';
import { useMemo, useRef } from 'react';
import { useApp } from '../../contexts/AppContext';
import ResourceActive from './shared/ResourceActive';
import ResourceTeamMember from './shared/ResourceTeamMember';
import Toolbar from './shared/Toolbar';
import getTimezones from './utils/getTimezones';

const Home = () => {
  const { teamMembers } = useApp();

  const schedulerRef = useRef()

  const schedulerConfig: Partial<SchedulerConfig> = useMemo(() => ({
    startDate: new Date(),
    endDate: new Date(2024, 4, 1),
    viewPreset: 'hourAndDay',
    resourceStore: {
      readUrl: `${process.env.REACT_APP_API_URL}/team_members`,
      autoLoad: true
    },
    eventStore: {
      readUrl: `${process.env.REACT_APP_API_URL}/events`,
      autoLoad: true
    },
    dependencies: [{
      id: 1,
      from: '951868d1-a84d-464d-b828-150bf036922e',
      to: '2ef2639b-823e-4e88-9bd8-4712d401c6d1', 
      type: 0
    }],
    features: {
      eventTooltip: {
        template: (data: any) => getTimezones(data, teamMembers)
      }
    },
    columns: [
      {
        type: 'widget',
        text: 'Team Member',
        width: 220,
        renderer: ({ record: { data } }: any) => <ResourceTeamMember teamMember={data} />
      },
      {
        type: 'widget',
        text: 'Status',
        width: 85,
        renderer: () => <ResourceActive />
      }
    ],
  
  }), [teamMembers]);

  return (
    <>
      <Toolbar scheduler={schedulerRef} />
      <BryntumScheduler
        ref={schedulerRef}
        {...schedulerConfig}
        onEventSelectionChange={(data: any) => console.log('data', data)}
      />
    </>
  );
}

export default Home;
