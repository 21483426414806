import { formatInTimeZone } from 'date-fns-tz';

const getTimezones = ({ eventRecord: { originalData } }: any, teamMembers: I.TeamMember[]) => {
  const team_member = teamMembers.find(({ id }) => id === originalData.resourceId)!;

  let start_date = originalData.startDate;
  start_date = formatInTimeZone(start_date, team_member.timezone, 'yyyy/MM/dd hh:mm a');
  let end_date = originalData.endDate;
  end_date = formatInTimeZone(end_date, team_member.timezone, 'yyyy/MM/dd hh:mm a');

  return `
    <b>${originalData.name}</b>
    <br /><br />
    <b>Local time:</b> <br />
    ${team_member.timezone}<br />
    Start date: ${start_date}<br />
    End date: ${end_date}
  `;
}

export default getTimezones