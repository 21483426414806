import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import TeamMembers from '../TeamMembers';
import Languages from '../Languages';
import Phases from '../Phases';

export default function BasicTabs() {
  const { location, ...history } = useHistory();

  const paths = ['/settings/team-members', '/settings/languages', '/settings/phases']

  const handleChange = (event: React.SyntheticEvent, newValue: number) => history.push(paths[newValue])

  const tabIndex = paths.findIndex((path) => location.pathname.includes(path))

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChange}>
          <Tab label="Team Members" />
          <Tab label="Languages" />
          <Tab label="Phases" />
        </Tabs>
      </Box>
      <Switch>
        <Route path="/settings/team-members" render={() => <TeamMembers />} />
        <Route path="/settings/languages" render={() => <Languages />} />
        <Route path="/settings/phases" render={() => <Phases />} />
      </Switch>
    </Box>
  );
}