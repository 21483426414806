import { BrowserRouter as Router } from "react-router-dom";

import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Header from './components/Header';
import Routes from "./Routes";
import { AppProvider } from "./contexts/AppContext";
import SnackbarProvider from "./contexts/SnackbarContext";

const App = () => (
  <SnackbarProvider>
    <AppProvider>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Router>
          <Header />
          <Routes />
        </Router>
      </LocalizationProvider>
    </AppProvider>
  </SnackbarProvider>
);

export default App;
